import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo";
import Navbar from "../components/_App/Navbar";
import MainBanner from "../components/ITStartup/MainBanner";
import Features from "../components/ITStartup/Features";

import Team from "../components/Common/Team";

import Footer from "../components/_App/Footer";

const ITStartup = () => (
    <Layout>
        <SEO title="Home" />
        <Navbar />
        <MainBanner />
        <Features />
        <Team />
        <Footer />
    </Layout>
)

export default ITStartup;
